<div class="position-relative">
    <span [class.blurred]="showFileUpload">
        <ng-content></ng-content>
    </span>
    <div class="position-absolute w-100 h-100 bg-white-50 {{showFileUpload ? 'd-flex' : 'd-none'}}"
         style="top: 0">
        <app-file-select [needUploadButton]="false"
                         [dragAndDropOptions]="dragAndDropOptions"
                         (onDraggingOverWindow)="showFileUploader($event)"
                         class="w-100 h-100"
                         size="full">
        </app-file-select>
    </div>
</div>
