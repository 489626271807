import {Component, Input, OnInit} from '@angular/core';
import {SmartTableDefinition} from '../table/smart-table.component';
import {HumanReadablePipe} from '../../../../@theme/pipes/human-readable.pipe';

@Component({
    selector: 'app-self-generate-table',
    templateUrl: './self-generate-table.component.html',
})
export class SelfGenerateTableComponent implements OnInit {
    @Input() data: any[];
    @Input() propertiesToSkip: string[];
    @Input() rowClassFn: (row) => string;
    definition: SmartTableDefinition = {
        rowClass: () => null,
        cols: [],
    };

    constructor(private humanReadablePipe: HumanReadablePipe) {
    }

    ngOnInit(): void {
        this.generateTableProperties();
    }

    generateTableProperties() {
        if (!this.data.length) return;
        this.definition.rowClass = this.rowClassFn ? value => this.rowClassFn(value) : null;
        this.definition.cols = Object.entries(this.data[0]).map(([key, value]) => ({
            name: this.humanReadablePipe.transform(key),
            prop: key,
            type: key.endsWith('_at') ? 'dateTime' : null,
            shouldShow: () => !this.propertiesToSkip.includes(key),
        }));
    }
}
