import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmComponent} from './confirm.component';
import {ConfirmService} from './confirm.service';
import {ConfirmDirective} from './confirm.directive';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {AdvancedInputModule} from '../../@portal-theme/components/advanced-input/advanced-input.module';
import {AdvancedInputScannerModule} from '../../@portal-theme/components/advanced-input-scanner/advanced-input-scanner.module';
import {DatepickerSelectModule} from '../../@portal-theme/components/datepicker/datepicker-select.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NgSelectModule,
        AdvancedInputModule,
        AdvancedInputScannerModule,
        DatepickerSelectModule,
    ],
    declarations: [
        ConfirmComponent,
        ConfirmDirective,
    ],
    exports: [
        ConfirmComponent,
        ConfirmDirective,
    ],
})

export class ConfirmModule {
    static forRoot(): ModuleWithProviders<ConfirmModule> {
        return {
            ngModule: ConfirmModule,
            providers: [
                ConfirmService,
            ],
        };
    }
}
