import {Component, Input} from '@angular/core';
import {LabResultsSummarySerializer, LabResultSummarySerializer} from '../../../../@core/api.service';
import {LabResult} from '../../../../models/models';
import {ContentDefinitions} from '../../../../definitions/definitions';

interface SummaryLabResult extends LabResultSummarySerializer {
    labTestLink?: string;
    colorClass?: string;
}

type Summary = {[key in keyof LabResultsSummarySerializer]: SummaryLabResult};

@Component({
    selector: 'app-latest-lab-results',
    templateUrl: './latest-lab-results.component.html',
    styleUrls: ['./latest-lab-results.component.scss'],
})
export class LatestLabResultsComponent {
    @Input() summary: Summary;
    @Input() isTable = true;

    ngOnChanges() {
        if (!this.summary) return;

        Object.entries(this.summary).forEach(([key, result]) => {
            if (!result) return;

            this.summary[key] = {
                ...result,
                labTestLink: `${ContentDefinitions.LAB_TEST.getPath(result.lab_test)}`,
                colorClass: LabResult.getColorClass(result),
            } as SummaryLabResult;
        });
    }
}
