import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbMonthStruct} from '../../../../utils/date.utils';

@Component({
    selector: 'app-month-year-select',
    templateUrl: './month-year-select.component.html',
    styleUrls: ['./month-year-select.component.scss'],
})

export class MonthYearSelectComponent implements OnInit {
    @Input() startYear = 2018;
    @Input() steppingEnabled = true;
    @Input() hasFuture = false;
    @Input() yearMonth: NgbMonthStruct;
    @Output() yearMonthChange: EventEmitter<NgbMonthStruct> = new EventEmitter();

    now = new Date();
    filter: NgbMonthStruct = {year: this.now.getFullYear(), month: this.now.getMonth() + 1};
    years: number[];
    monthsOfYear: {[year: number]: number[]} = {};

    ngOnInit() {
        setTimeout(() => {
            this.filter = {
                year: this.yearMonth.year ? this.yearMonth.year : this.filter.year,
                month: this.yearMonth.month ? this.yearMonth.month : this.filter.month,
            };
            this.onFilterChanged();
        });
        this._setOptions();
    }

    private _setOptions() {
        for (let y = this.startYear; y <= this.now.getFullYear() + (this.hasFuture ? 1 : 0); y++) {
            this.monthsOfYear[y] = [];
            for (let m = 1; m <= (this.now.getFullYear() + (this.hasFuture ? 1 : 0) > y ? 12 : this.now.getMonth() + 1); m++) {
                this.monthsOfYear[y].push(m);
            }
        }
        this.years = Object.keys(this.monthsOfYear).map(y => +y);
    }

    onFilterChanged() {
        if (!this.monthsOfYear[this.filter.year]?.includes(this.filter.month)) {
            const years = Object.keys(this.monthsOfYear);
            const availableYear = years.includes(this.filter.year.toString()) ? this.filter.year : +years[years.length-1];
            this.filter = {year: availableYear, month: this.monthsOfYear[availableYear][this.monthsOfYear[availableYear].length-1]};
        }
        this.yearMonthChange.emit(this.filter);
    }

    onMoveRight() {
        if (this.filter.month === 12) {
            this.filter.year += 1;
            this.filter.month = 1;
        } else {
            this.filter.month += 1;
        }

        this.onFilterChanged();
    }

    onMoveLeft() {
        if (this.filter.month === 1) {
            this.filter.year -= 1;
            this.filter.month = 12;
        } else {
            this.filter.month -= 1;
        }

        this.onFilterChanged();
    }

    canMoveRight() {
        const currentYear = this.now.getFullYear();
        const currentMonth = this.now.getMonth() + 1;

        return this.filter.year === currentYear + (this.hasFuture ? 1 : 0) && this.filter.month === currentMonth;
    }

    canMoveLeft() {
        return this.filter.year === this.startYear && this.filter.month === 1;
    }
}
