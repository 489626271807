import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CheckFacilityDirective} from './check-facility/check.facility.directive';
import {NoFacilityMessageComponent} from './no-facility-message/no-facility-message.component';
import {IllustrationModule} from '../../../@shared/illustration/illustration.module';
import {CheckNoFacilityDirective, DisableCheckNoFacilityDirective} from './check-no-facility/check-no-facility.directive';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        IllustrationModule,
    ],
    declarations: [
        CheckFacilityDirective,
        CheckNoFacilityDirective,
        DisableCheckNoFacilityDirective,
        NoFacilityMessageComponent,
    ],
    exports: [
        CheckFacilityDirective,
        CheckNoFacilityDirective,
        DisableCheckNoFacilityDirective,
        NoFacilityMessageComponent,
    ],
})
export class CheckFacilityModule {
}
