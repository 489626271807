<div class="d-flex align-items-center">
    <fal i="user" class="icon text-primary"></fal>
    <app-advanced-input [(ngModel)]="filter.params.patient_status"
                        [selectItems]="options"
                        [clearable]="false"
                        type="select"
                        class="body-select"
                        style="width: 12em;"
                        bindValue="value"
                        bindLabel="name">
    </app-advanced-input>
</div>
