import {Component, Input, OnChanges} from '@angular/core';

@Component({
    selector: 'app-change',
    template: `
        <span class="change text-{{inheritColor ? '' : rating}} no-line-break" *ngIf="showZero || (!showZero && processed)">
            <fal [i]="direction ? 'arrow-' + direction : 'minus'"></fal>
            {{processed | number:'1.0-' + decimals}}%
        </span>
    `,
})
export class ChangeComponent implements OnChanges {
    @Input() change: number;
    @Input() array: any[];
    @Input() positiveDirection: 'up' | 'down' | '' = 'up';
    @Input() showZero = true;
    @Input() inheritColor = false;
    @Input() decimals = 2;
    @Input() type: 'ratio' | 'difference' = 'ratio';
    processed: number;
    rating: string;
    direction: 'up' | 'down' | '';

    ngOnChanges() {
        let change;
        if (this.change) change = this.change;
        else if (this.array) change = ChangeComponent.getChange(this.array, this.type);
        this.processed = Math.abs(change) * 100;
        this.direction = change > 0 ? 'up' : change < 0 ? 'down' : '';
        if (this.positiveDirection == 'up') {
            this.rating = (this.direction == 'up') ? 'success' : (this.direction == 'down') ? 'danger' : '';
        } else if (this.positiveDirection == 'down') {
            this.rating = (this.direction == 'down') ? 'success' : (this.direction == 'up') ? 'danger' : '';
        } else {
            this.rating = '';
        }
    }

    static getChange(a, type: 'ratio' | 'difference' = 'ratio') {
        if (!a.length) return null;
        const f = a[0] ? (a[0].count || a[0] || 0) : 0;
        const l = a[a.length - 1] ? (a[a.length - 1].count || a[a.length - 1] || 0) : 0;
        if (type == 'ratio') return (f - l) / l;
        if (type == 'difference') return f - l;
    }
}
