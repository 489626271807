import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {FaModule} from '../../../../@theme/components/fa/fa.module';
import {AdvancedInputScannerComponent} from './advanced-input-scanner.component';
import {ScannerModule} from '../../../@portal-shared/scanner/scanner.module';
import {AdvancedInputModule} from '../advanced-input/advanced-input.module';
import {ThemeModule} from '../../../../@theme/theme.module';

@NgModule({
    declarations: [
        AdvancedInputScannerComponent,
    ],
    imports: [
        CommonModule,
        ZXingScannerModule,
        FaModule,
        ScannerModule,
        AdvancedInputModule,
        ThemeModule,
    ],
    exports: [
        AdvancedInputScannerComponent,
    ],
})
export class AdvancedInputScannerModule {
}
