import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-no-content',
    template: `
        <div class="card border-primary text-center text-primary mb-3">
            <div class="card-body">
                <h5 *ngIf="title" class="card-title">{{title}}</h5>
                <p *ngIf="caption" class="card-text">{{caption}}</p>
                <ng-content></ng-content>
                <ng-container *ngIf="btnCallback">
                    <a class="btn btn-primary" tabindex="-1" (click)="btnCallback()"><fal i="plus"></fal> {{btnText}}</a>
                </ng-container>
            </div>
        </div>
    `,
})
export class NoContentComponent {
    @Input() title = 'No data to display';
    @Input() caption = 'The administrator might have to do additional setup to make this feature available';
    @Input() btnText = 'Edit';
    @Input() btnCallback;
}
