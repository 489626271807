<div *ngIf="holidayEvent"
     class="d-flex align-items-center p-1 bg-white rounded cursor-default"
     style="line-height: 1.2; font-size: 1rem; border: 1px solid #f4f5fc"
     placement="right">
        <div class="flex-column">
            <small class="strong">{{holidayEvent.name}}</small>
        </div>
</div>
<div *ngIf="event"
     class="d-flex align-items-center p-1 bg-white rounded cursor-default"
     style="line-height: 1.2; font-size: 1rem; border: 1px solid #f4f5fc"
     placement="right"
     [hoverActions]="actions">
        <div class="flex-column">
            <app-user-icon [user]="event.user" size="sm" classList="mr-2"></app-user-icon>
        </div>
        <div class="flex-column">
            <small class="d-block">{{event.physicianTeam?.facility.name}}</small>
            <small class="strong">{{event.typeText}}</small>
        </div>
</div>
