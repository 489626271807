import {AfterViewInit, Directive, ElementRef, OnDestroy} from '@angular/core';
import * as ifr from 'iframe-resizer';

@Directive({
    selector: '[appIframeResizer]',

})
export class IframeResizerDirective implements AfterViewInit, OnDestroy {
    component: ifr.IFrameComponent;

    constructor(public element: ElementRef) {
    }

    ngAfterViewInit() {
        const components = ifr['iframeResizer']({
            checkOrigin: false,
            heightCalculationMethod: 'documentElementScroll',
            log: false,
        }, this.element.nativeElement);
        this.component = components && components.length > 0 ? components[0] : null;
    }

    ngOnDestroy() {
        if (this.component && this.component.iFrameResizer) {
            this.component.iFrameResizer.close();
        }
    }
}
