<div class="modal-header">
    <h4 class="modal-title" id="modal-title" data-test-id="refresh-modal">A new version is available</h4>
</div>
<div class="modal-body">
    <div class="alert alert-warning">
        <strong>The application needs to be updated to ensure that every functionality is working as intended.</strong>
        This tab will be automatically refreshed in <strong>{{secondsUntilRefresh}} second{{secondsUntilRefresh > 1 ? 's' : ''}}</strong>.
    </div>
</div>
<div class="modal-footer d-flex justify-content-between">
    <button *ngIf="isLoggedIn" type="button" class="btn btn-danger" (click)="cancelAutoRefresh()">Cancel</button>
    <button [class.btn-block]="!isLoggedIn" type="button" class="btn btn-success" (click)="refresh()">Refresh Now</button>
</div>
