import {Directive, Optional, TemplateRef, ViewContainerRef} from '@angular/core';
import {FacilityChooserService, OrganizationFacility} from '../../../../@core/facility-chooser.service';
import {FcComponent} from '../../../../@core/fc-component';

@Directive({
    selector: '[appCheckNoFacility]',
})
export class CheckNoFacilityDirective extends FcComponent {
    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                @Optional() private disableNoFacility: DisableCheckNoFacilityDirective,
                fc: FacilityChooserService) {
        super(fc);
    }

    compOnFacChange(orgFac: OrganizationFacility) {
        if (!orgFac.facility && !this.disableNoFacility) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}

@Directive({
    selector: '[appDisableCheckNoFacility]',
})
export class DisableCheckNoFacilityDirective {
    // This is a dummy directive.
    // It is used to check if this is a parent of any [appCheckNoFacility] directive
    // and if it is, then we override the functionality of [appCheckNoFacility]
}
