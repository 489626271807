import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DownloadService} from '../../../@core/download.service';

@Directive({
    selector: '[appB64img]',
})
export class B64imgDirective implements OnChanges {
    @Input() api;
    @Input() imgid: number;
    @Input() type = 'jpg';

    constructor(private ds: DownloadService, private el: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.imgid) {
            if (this.api && this.imgid) {
                this.ds.getBASE64Image(this.api, this.imgid).subscribe(str => {
                    this.el.nativeElement.setAttribute('src', `data:image/${this.type};base64, ${str}`);
                });
            }
        }
    }
}
