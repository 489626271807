import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationComponent} from './navigation.component';
import {NavigationSectionDirective} from './navigation-section.directive';
import {ScrollNavigationComponent} from './scroll-navigation.component';
import {TabNavigationComponent} from './tab-navigation.component';
import {ThemeModule} from '../../../@theme/theme.module';

@NgModule({
    imports: [
        CommonModule,
        ThemeModule,
    ],
    declarations: [
        NavigationComponent,
        ScrollNavigationComponent,
        TabNavigationComponent,
        NavigationSectionDirective,
    ],
    exports: [
        ScrollNavigationComponent,
        TabNavigationComponent,
        NavigationSectionDirective,
    ],
})
export class NavigationModule {
}
