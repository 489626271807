import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatepickerSelectComponent} from './datepicker-select.component';
import {DatepickerRangeSelectComponent} from './datepicker-range-select.component';
import {DatepickerComponent} from './datepicker.component';
import {ThemeModule} from '../../../../@theme/theme.module';
import {NgbDatepickerRange} from './datepicker-range.component';
import {ClickOutsideModule} from 'ng-click-outside';
import {DatepickerMultiSelectComponent} from './datepicker-multi-select.component';
import {NgSelectModule} from '@ng-select/ng-select';

const COMPONENTS = [
    DatepickerComponent,
    DatepickerSelectComponent,
    DatepickerRangeSelectComponent,
    DatepickerMultiSelectComponent,
    NgbDatepickerRange,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        ThemeModule,
        ClickOutsideModule,
        NgSelectModule,
    ],
    exports: COMPONENTS,
})
export class DatepickerSelectModule {
}
