import {NgModule} from '@angular/core';
import {LatestLabResultsComponent} from './latest-lab-results.component';
import {ThemeModule} from '../../../../@theme/theme.module';

@NgModule({
    declarations: [
        LatestLabResultsComponent,
    ],
    imports: [
        ThemeModule,
    ],
    exports: [
        LatestLabResultsComponent,
    ],
})
export class LatestLabResultsModule {
}
