<fal i="calendar" class="text-primary"></fal>
<button *ngIf="steppingEnabled"
        (click)="onMoveLeft()"
        [disabled]="canMoveLeft()"
        class="action-icon">
    <fal i="chevron-left"></fal>
</button>
<select [(ngModel)]="filter.month"
        (ngModelChange)="onFilterChanged()"
        class="body-select w-sm">
    <option *ngFor="let month of monthsOfYear[filter.year]" [ngValue]="month">{{ month }}</option>
</select>
<select [(ngModel)]="filter.year"
        (ngModelChange)="onFilterChanged()"
        class="body-select">
    <option *ngFor="let year of years" [ngValue]="year">{{ year }}</option>
</select>
<button *ngIf="steppingEnabled"
        (click)="onMoveRight()"
        [disabled]="canMoveRight()"
        class="action-icon">
    <fal i="chevron-right"></fal>
</button>
