import {Component, HostListener, Input} from '@angular/core';
import {ReviewTypeKey} from '../../../../definitions/definitions';
import {PreviewService} from '../../../@portal-shared/hover-components/services/preview.service';
import {TzDatePipe} from '../../../../@theme/pipes/tz-date.pipe';
import {User} from '../../../../models/user';

@Component({
    selector: 'app-review-indication',
    template: `
        <app-user-badges *ngIf="entry"
                         [users]="users"
                         [tooltips]="tooltips"
                         [addOnIcons]="addOnIcons"
                         moreTooltipSuffix="related notes">
        </app-user-badges>
        <app-user-icon *ngIf="!entry?.related_tasks?.length && entry?.reviewed_by"
                       [user]="entry.reviewed_by"
                       size="xs"
                       classList="pin-shadow"
                       addOnIcon="check"
                       tooltipPlacement="bottom"
                       tooltipInfo="Marked as Reviewed @ {{entry.reviewed_at | tzDate}}">
        </app-user-icon>
    `,
    styleUrls: ['./review-indication.component.scss'],
})
export class ReviewIndicationComponent {
    @Input() entry: any;
    @Input() type: ReviewTypeKey;
    @Input() disablePreview = true;
    @Input() limit = 3;

    private _users: User[];

    get users() {
        if (!this._users || this.entry?.related_tasks?.length !== this._users.length) {
            this._users = this.entry?.related_tasks?.map(x => x.user);
        }
        return this._users;
    }

    private _tooltips: string[];

    get tooltips() {
        if (!this._tooltips || this.entry?.related_tasks?.length !== this._tooltips.length) {
            this._tooltips = this.entry?.related_tasks?.map(x => this.getTooltipText(x));
        }
        return this._tooltips;
    }

    private _addOnIcons: string[];

    get addOnIcons() {
        if (!this._addOnIcons || this.entry?.related_tasks?.length !== this._addOnIcons.length) {
            this._addOnIcons = this.entry?.related_tasks?.map(x => this.entry.reviewed_by?.id === x.user.id ? 'check' : null);
        }
        return this._addOnIcons;
    }

    constructor(private tzDatePipe: TzDatePipe,
                private preview: PreviewService) {
    }

    getTooltipText(task) {
        return this.entry.reviewed_by && this.entry.reviewed_at && this.entry.reviewed_by.id === task.user.id ?
            `Marked as Reviewed @ ${this.tzDatePipe.transform(this.entry.reviewed_at)}` :
            null;
    }

    @HostListener('click', ['$event'])
    openSlidePanel(event) {
        if (this.disablePreview) return;

        if (event) event.stopPropagation();
        this.preview.openPreviewComponent(this.type, this.entry);
    }
}
