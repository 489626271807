import {Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {APIService} from '../../../../@core/api.service';

@Component({
    selector: 'app-floor-plan',
    templateUrl: './floor-plan.component.html',
    host: {class: 'card'},
})
export class FloorPlanComponent {
    @Input() floorPlan;
    @ContentChild('addOn') addOn: TemplateRef<any>;
    viewSet = this.api.urls.FloorPlanViewSet;

    constructor(private api: APIService) {
    }
}
