import {Directive, ElementRef} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {take} from 'rxjs/operators';

@Directive({
    selector: '[appFilterModalButton]',
    standalone: true,
})
export class FilterModalCloseDirective {
    modalRefs: NgbModalRef[];

    constructor(private el: ElementRef,
                private modalService: NgbModal) {
        this.modalService.activeInstances.pipe(take(1)).subscribe(instances => this.modalRefs = instances);

        this.el.nativeElement.addEventListener('click', () => {
            this.modalRefs?.find(instance => instance.componentInstance.name === 'FilterModalComponent')?.close();
        });
    }
}
