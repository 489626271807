import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdvancedInputComponent} from './advanced-input.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {ThemeModule} from '../../../../@theme/theme.module';
import {DatepickerSelectModule} from '../datepicker/datepicker-select.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {SelectOptionComponent} from '../../../@portal-shared/select-option/select-option.component';

@NgModule({
    declarations: [AdvancedInputComponent],
    imports: [
        CommonModule,
        ZXingScannerModule,
        ThemeModule,
        DatepickerSelectModule,
        NgSelectModule,
        SelectOptionComponent,
    ],
    exports: [AdvancedInputComponent],
})
export class AdvancedInputModule {
}
