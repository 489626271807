import {Component, Input} from '@angular/core';
import {DragAndDropOptions} from '../file-select.component';

@Component({
    selector: 'app-drag-and-drop-container',
    templateUrl: './drag-and-drop-container.component.html',
    styleUrl: './drag-and-drop-container.component.scss',
})
export class DragAndDropContainerComponent {
    @Input() dragAndDropOptions: DragAndDropOptions;
    showFileUpload: boolean = false;

    showFileUploader(isDragging: boolean) {
        this.showFileUpload = isDragging;
    }
}
