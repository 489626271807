import {ChangeDetectorRef, Directive, ElementRef, Input, NgZone, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {HoverActionsDirective} from './hover-actions.directive';
import {Patient} from '../../../../models/patient';
import {UserAuthService} from '../../../../@core/user-auth.service';
import {PatientActionsService} from '../services/patient-actions.service';
import {SlidePanelService} from '../../../slide-panel/slide-panel.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Directive({
    selector: '[hoverPatientActions]',
    host: {'class': 'smart-link'},
})
export class HoverPatientActionsDirective extends HoverActionsDirective implements OnInit {
    @Input('patient') patient: Patient;

    constructor(
        el: ElementRef,
        vc: ViewContainerRef,
        renderer: Renderer2,
        authService: UserAuthService,
        zone: NgZone,
        slidePanelService: SlidePanelService,
        modalService: NgbModal,
        router: Router,
        cdRef: ChangeDetectorRef,
        private patientActions: PatientActionsService,
    ) {
        super(el, vc, renderer, authService, zone, slidePanelService, modalService, router, cdRef);
    }

    ngOnInit(): void {
        this.actions = this.patientActions.getPatientActions(this.patient);
    }
}
