import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentHeaderComponent} from './component-header/component-header.component';
import {FilterDirective} from './filter.directive';
import {AddonDirective} from './addon.directive';
import {PageHeaderComponent} from './page-header/page-header.component';
import {ResponsiveDropdownDirective} from './responsive-dropdown.directive';
import {ResponsiveDropdownComponent} from './page-header/responsive-dropdown/responsive-dropdown.component';
import {DropdownTargetDirective} from './dropdown-target.directive';
import {ThemeModule} from '../../../@theme/theme.module';
import {BreadcrumbsModule} from '../components/breadcrumbs/breadcrumbs.module';
import {FilterModalComponent} from './component-header/filter-modal.component';
import {FilterModalCloseDirective} from './component-header/filter-modal-close.directive';

@NgModule({
    declarations: [
        ComponentHeaderComponent,
        FilterDirective,
        AddonDirective,
        PageHeaderComponent,
        ResponsiveDropdownDirective,
        ResponsiveDropdownComponent,
        DropdownTargetDirective,
        FilterModalComponent,
    ],
    imports: [
        CommonModule,
        BreadcrumbsModule,
        ThemeModule,
        FilterModalCloseDirective,
    ],
    exports: [
        ComponentHeaderComponent,
        FilterDirective,
        AddonDirective,
        PageHeaderComponent,
        ResponsiveDropdownDirective,
        DropdownTargetDirective,
    ],
})
export class PageHeaderModule {
}
