import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScannerComponent} from './scanner.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {FaModule} from '../../../@theme/components/fa/fa.module';

@NgModule({
    declarations: [
        ScannerComponent,
    ],
    imports: [
        CommonModule,
        ZXingScannerModule,
        FaModule,
    ],
    exports: [
        ScannerComponent,
    ],
})
export class ScannerModule {
}
