import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-add-placeholder',
    template: `
        <div class="add-placeholder"  [ngbTooltip]="tooltip || null">
            <div class="add-placeholder-inner">
                <div class="line"></div>
                <div class="icon-cnt">
                    <far [i]="icon"></far>
                </div>
                <div class="line"></div>
            </div>
        </div>
    `,
    styleUrls: ['./add-placeholder.component.scss'],
})
export class AddPlaceholderComponent {
    @Input() icon = 'plus';
    @Input() backIcon: string;
    @Input() tooltip: string;
    activeIcon;

    constructor() {
        this.activeIcon = this.icon;
        if (this.icon == 'plus') this.backIcon = 'minus';
    }
}
