<table class="table table-sm small w-100" *ngIf="isTable; else nonTable">
    <th>Recent Lab Results</th>
    <td>
        <ng-container *ngTemplateOutlet="labSummary"></ng-container>
    </td>
</table>

<ng-template #nonTable>
    <ng-container *ngTemplateOutlet="labSummary"></ng-container>
</ng-template>

<ng-template #labSummary>
    <div class="d-flex align-items-start justify-content-start row">
        <ng-container *ngTemplateOutlet="labSummaryResult; context: {summaryTitle: 'Creatinine',  summary: summary.creatinine}"></ng-container>
        <ng-container *ngTemplateOutlet="labSummaryResult; context: {summaryTitle: 'INR',  summary: summary.inr}"></ng-container>
        <ng-container *ngTemplateOutlet="labSummaryResult; context: {summaryTitle: 'A1C',  summary: summary.a1c}"></ng-container>
        <ng-container *ngTemplateOutlet="labSummaryResult; context: {summaryTitle: 'GFR African American',  summary: summary.gfr_aa}"></ng-container>
        <ng-container *ngTemplateOutlet="labSummaryResult; context: {summaryTitle: 'GFR Non-African American',  summary: summary.gfr_non_aa}"></ng-container>
   </div>
</ng-template>

<ng-template #labSummaryResult let-summary="summary" let-summaryTitle="summaryTitle">
    <div class="col">
        <div class="mr-1">{{summaryTitle}}:</div>
        <div class="d-flex align-items-center mr-2">
            <ng-container *ngIf="summary?.quantity; else noDataShort">
                <span class="text-{{summary.colorClass}}"
                      [class.strong]="summary.is_critical || summary.is_abnormal">
                    {{summary.quantity}} {{summary.unit}}
                </span>
                <app-action-icon icon="arrow-right"
                                 [routerLink]="summary.labTestLink"
                                 size="xs"
                                 tooltip="Open Lab Test"
                                 class="my-n1">
                </app-action-icon>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #noDataShort>
    <div class="text-muted">
        <fal i="minus"></fal>
    </div>
</ng-template>
