import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReviewTypeKey} from '../../../../../definitions/definitions';
import {SmartTableColSpec, SmartTableColTemplate, SmartTableDefinition} from '../smart-table.component';
import {TableRowComponent} from '../table-row/table-row.component';

@Component({
    selector: 'tr[smart-table-header]',
    templateUrl: './table-header.component.html',
})
export class TableHeaderComponent<T = any> {
    @Input() data: T[];
    @Input() definition: SmartTableDefinition<T>;
    @Input() shouldShowDetails;
    @Input() insertTemplateCols: SmartTableColTemplate[];
    @Input() hasSorting;
    @Input() sorting;
    @Input() selectable;
    @Input() allRowsSelected;
    @Input() reviewType: ReviewTypeKey;
    @Input() paginated = false;

    @Output() selectAll = new EventEmitter<boolean>();
    @Output() sortSelect = new EventEmitter<SmartTableColSpec<T>>();

    isSortable(col: SmartTableColSpec<T>) {
        if (this.paginated) return this.hasSorting && col.sortKey;
        return this.hasSorting && col.type != 'colorBar' && !col.sortDisabled;
    }

    getInsertTemplateCols(index?: number) {
        return TableRowComponent.getInsertTemplateCols(this.insertTemplateCols, this.definition.cols.length, index);
    }

    onSelectAll(event: boolean) {
        this.selectAll.emit(event);
    }

    onSortSelect(sort: SmartTableColSpec<T>) {
        this.sortSelect.emit(sort);
    }
}
