import {Component, Input, OnInit} from '@angular/core';
import {Filter} from '../../../../@core/filter';

@Component({
    selector: 'app-patient-status-select',
    templateUrl: './patient-status-select.component.html',
    styleUrls: ['./patient-status-select.component.scss'],
})
export class PatientStatusSelectComponent<T extends {patient_status: string}> implements OnInit {
    @Input() filter: Filter<T>;

    options = [
        {name: 'All Statuses', value: null},
        {name: 'Active', value: 'ACTIVE_EXCLUDE_EXTENDED_LEAVE'},
        {name: 'In House', value: 'IN'},
        {name: 'Extended Leave', value: 'ON_EXTENDED_LEAVE'},
        {name: 'Outpatient', value: 'OUTPATIENT'},
        {name: 'Inactive', value: 'INACTIVE'},
        {name: 'Discharge', value: 'DISCHARGED'},
    ];

    ngOnInit() {
        if (!this.filter.params.patient_status && this.filter.params.patient_status !== null) {
            this.filter.params.patient_status = this.options[1].value;
        }
    }
}
