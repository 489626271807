<th class="w-0" *ngIf="selectable">
    <app-toggle [ngModel]="allRowsSelected" (ngModelChange)="onSelectAll($event)" size="sm" ngbTooltip="Select All"></app-toggle>
</th>
<ng-container *ngFor="let col of definition.cols; let i = index">
    <ng-container *ngFor="let t of getInsertTemplateCols(i)">
        <ng-container *ngTemplateOutlet="insertTh; context: {t: t}"></ng-container>
    </ng-container>
    <ng-container *ngxPermissionsOnly="col.permissions">
        <ng-container *ngIf="!col.onlyDetail || (col.onlyDetail && shouldShowDetails)" [ngSwitch]="col.type">
            <ng-container *ngIf="col.shouldShow; else tht">
                <ng-container *ngIf="col.shouldShow()" [ngTemplateOutlet]="tht"></ng-container>
            </ng-container>
            <ng-template #tht>
                <ng-container *ngIf="col.checkNoFacility; else defaultHeaderTemplate">
                    <ng-container *appCheckNoFacility [ngTemplateOutlet]="defaultHeaderTemplate"></ng-container>
                </ng-container>
            </ng-template>

            <ng-template #defaultHeaderTemplate>
                <th (click)="onSortSelect(col)"
                    [style.cursor]="isSortable(col) ? 'pointer' : null"
                    [style.width]="col.w || col.w === 0 ? col.w + '%' : null"
                    class="{{col.w ? ' w-' + col.w : ''}}{{col.justify ? ' text-' + col.justify : ''}}{{col.align ? ' align-' + col.align : ''}}{{col.onlyPrint ? ' d-none d-print-table-cell' : ''}}">
                    <span class="d-inline-flex align-items-center">
                        <span>{{col.name}}</span>
                        <ng-container *ngIf="isSortable(col)">
                            <fas *ngIf="sorting?.col == col; else sortIndicator" class="text-primary" [i]="sorting.desc ? 'caret-up' : 'caret-down'"></fas>
                            <ng-template #sortIndicator>
                                <fal class="text-muted" i="caret-down"></fal>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="col.headerHint">
                            <app-hint class="ml-1" [iconClasses]="'small'">{{col.headerHint}}</app-hint>
                        </ng-container>
                    </span>
                </th>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-container>
<th *ngIf="reviewType" aria-label="empty"></th>
<ng-container *ngFor="let t of getInsertTemplateCols()">
    <ng-container *ngTemplateOutlet="insertTh; context: {t: t}"></ng-container>
</ng-container>

<ng-template #insertTh let-t="t">
    <ng-container *ngIf="t.headingTemplate; else regularTh">
        <ng-container *ngTemplateOutlet="t.headingTemplate; context: {meta: t.meta, data: data}"></ng-container>
    </ng-container>
    <ng-template #regularTh>
        <th>{{t.heading}}</th>
    </ng-template>
</ng-template>
