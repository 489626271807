import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserAuthService} from '../../../../@core/user-auth.service';

@Component({
    selector: 'app-new-version-available-modal',
    templateUrl: './new-version-available-modal.component.html',
})
export class NewVersionAvailableModalComponent implements OnInit, OnDestroy {
    secondsUntilRefresh = 10 * 60;
    private interval: number;

    get isLoggedIn() {
        return UserAuthService.hasUser;
    }

    constructor(private activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
        this.interval = window.setInterval(() => {
            this.secondsUntilRefresh -= 1;
            if (this.secondsUntilRefresh <= 0) {
                this.refresh();
            }
        }, 1000);
    }

    ngOnDestroy(): void {
        if (this.interval) clearInterval(this.interval);
    }

    refresh() {
        this.activeModal.close('REFRESH');
    }

    cancelAutoRefresh() {
        clearInterval(this.interval);
        this.activeModal.dismiss('CLOSE');
    }
}
