import {Component, Input, OnChanges} from '@angular/core';
import {CalendarEvent, CalendarEventLike} from '../../../../models/calendar-event';
import {HoverAction} from '../../../@portal-shared/hover-components/hover-action.interface';
import {take} from 'rxjs/operators';
import {CalendarService} from '../../../../@core/calendar.service';
import {UserAuthService} from '../../../../@core/user-auth.service';

@Component({
    selector: 'app-calendar-event-label',
    templateUrl: './calendar-event-label.component.html',
})
export class CalendarEventLabelComponent implements OnChanges {
    @Input() event: CalendarEvent;
    @Input() holidayEvent: CalendarEventLike;
    actions: HoverAction[];

    constructor(private calendarService: CalendarService,
                private userAuthService: UserAuthService) {
    }

    ngOnChanges() {
        this.userAuthService.user.pipe(take(1)).subscribe(user => {
            this.actions = [{
                icon: 'pencil',
                colorClass: 'primary',
                caption: 'Edit',
                callback: () => this.calendarService.openEditEvent(this.event),
                permissions: ['PERMISSION_WRITE_VISITS'],
            }];
        });
    }
}
