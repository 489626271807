import {Component, EventEmitter, Output} from '@angular/core';
import {FilterService} from '../../../../@core/filter.service';

@Component({
    selector: 'app-floor-plan-view-options',
    template: `
        <ng-container *ngTemplateOutlet="fpViewOptions; context: {double: filterService.floorPlanView == 'double'}"></ng-container>
        <ng-template #fpViewOptions let-double="double">
            <div class="btn-group">
                <button class="btn btn-light"
                        [class.active]="!double"
                        (click)="viewChange('single')"
                        ngbTooltip="Single column view">
                    <fa i="square" [variant]="!double ? 'regular' : 'light'"></fa>
                </button>
                <button class="btn btn-light"
                        [class.active]="double"
                        (click)="viewChange('double')"
                        ngbTooltip="Two column view">
                    <fa i="th-large" [variant]="double ? 'regular' : 'light'"></fa>
                </button>
            </div>
        </ng-template>
    `,
})
export class FloorPlanViewOptionsComponent {
    @Output() optionsChanged = new EventEmitter<{floorPlanView: string}>();

    constructor(public filterService: FilterService) {
    }

    viewChange(viewType: 'single' | 'double') {
        this.filterService.floorPlanView = viewType;
        this.optionsChanged.emit({floorPlanView: viewType});
    }
}
